.bannerImg3{
    position: absolute;
    bottom: 2rem;
    right: 1rem;
}
.activitiesLists .card-title{
    font-family: var(--global-font-family);
    padding: 1em 0;
}
.activitiesLists .card{
    border: none;
    margin-bottom: 3rem;
    align-items: center;
    display: inline-block;
}
.activitiesLists .card-body{
    box-shadow: 0px 4px 4px rgba(192, 189, 189, 0.5);
    border-radius: 5px; 
    width: 100%;
    background: var(--global-color-lightblue);
}
.activitiesLists .card-text{
    font-size: 1rem;
    margin-bottom: 0 !important;
}
.storyBox h4{
    color: #666;
    margin-bottom: 1rem;
}
.article-para{
    font-family: var(--global-font-family-mn);
}