.bannerImg4{
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
}
.storyText{
    width: 80%;
    margin: 0 auto;
}
.storyFrameBox.col-md-6{
    width: 45%;
    margin: 1em;
}
.storyFrameBox{
    background-color: var(--global-color-white);
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    box-shadow: 0px 5px 4px rgba(221, 221, 221, 0.25);
    border-radius: 5px;
    margin-bottom: 2rem;
    font-family: var(--global-font-family);
    color: #718096;
    min-height: 430px;
}

@media only screen and (max-width: 600px) {
    .background{
        padding: 0.5rem;
    }
  }