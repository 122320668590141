.navbar{
    background-color: var(--global-color-white);
}
.logo{
    width: 90px;
}
.nav-items{
    width: 100%;
}
.nav-link{
    margin-right: 20px;    
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
    font-family: var(--global-font-family);
}
.btn.nav-link{
  line-height: 1.3;
}
.profile img{
    padding-left: 10px;
}
.profile svg{
    width: 30px;
    height: 30px;    
    margin-top: 10px;
    color: #102E46;
}
.profile .name{
    position: relative;
    top: -3px;
    padding-right: 10px;
}
.addTobasket{
    cursor: pointer;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link{
    border-bottom: 2px solid;
}