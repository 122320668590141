.footer{
    background: linear-gradient(180deg, #67C3F3 9.05%, #5A98F2 76.74%);
    text-align: left;
    padding: 3rem 0 !important;
    position: relative;
}
.footer h5{
    color: var(--global-color-white);
    font-weight: 700;
    padding-bottom: 1rem;
}
.footer ul{
    list-style: none;
    padding-left: 4rem;
    line-height: 2;
}
.footer a,.footer label,.footer p{
    color: var(--global-color-white);
    font-weight: 300;
    line-height: 1.8;
}
.secTitle{
    padding-left: 4rem;
}
.bgItem5{
    position: absolute;
    left: 0;
    bottom: 0;
}
.contactInfo svg{
    margin: 10px;
}