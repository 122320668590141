.offcanvas-header{
    border-bottom: 1px solid #fff;
}
.addToLists .list-group-item{
    background-color: var(--global-color-main);
    color: var(--global-color-white);
    border: none;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    display: flex;
    font-size: 16px;
}
.addToLists{ 
    border-bottom: 1px solid #fff;
    border-radius: 0px !important;
}
.addToLists .addTitle{
    width: 200px;
    font-family: var(--global-font-family-mn);
    font-size: 18px;
}
.addNum{
    border-right: 1px solid #fff;
    padding: 0 0.5rem 1.5rem;
    margin-right: 0.5rem;
}
.addToLists .price{
    padding-right: 0.5rem;
    width: 100px;
}
.price span{
    display: flex;
}
.addToLists svg{
    margin: 0.25rem;
}
.addTocartOff .offcanvas-title{
    font-size: 1rem !important;
    padding: 1rem;
    color: #fff ;
}
.trash,.price svg{
    cursor: pointer;
}