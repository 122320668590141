.bookDetail{
    background: url("../../assets/images/Vector.png") no-repeat top right;
}
.meesuImg{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.bookInfo{
    padding: 2rem;
}
.bookInfo .btn-link{
    font-size: 1.3rem;
    color: var(--global-text-color);
    font-weight: 600;
    text-align: left;
    text-decoration: none;
}
.bookInfo .btn-link svg{
    margin-right: 10px;
}
.bookInfo .col-md-4 img{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border: 8px solid #fff;
    margin: 8px;
}
.bookInfo h5{
    font-size: 1.5rem;
    font-family: var(--global-font-family-mn);
    color: var(--global-color-main);
    margin: 0.5rem 0 1rem;
}
.bookInfo p{
    font-family: var(--global-font-family-mn);
}
.bookInfo label{
    width: 120px;
    font-family: var(--global-font-family);
}
.bookInfo button{
    margin: 1rem 0.5rem 0 0;
}
.bookInfo .btn-primary{
    width: 160px;
}
.btn svg{
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
}
.btn-outline-primary svg{
    margin-right: 5px;
}
.btn-primary svg{
    margin-left: 5px;
}
.offcanvas-end{
    font-family: var(--global-font-family);
}
.offcanvas-title label{
    text-align: right;
    width: 58%;
}
.btn-outline-light{
    width: 100%;
    border-radius: 10px !important;
}