@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap');
@font-face {
  font-family: "Myanmar Sans Pro";
  src: url("./assets/fonts/MyanmarSansPro-Regular.ttf") format("truetype");
  }
body {
  margin: 0;
  padding: 0;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--global-font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --global-font-family: 'Mulish', sans-serif;
  --global-font-family-mn : "Myanmar Sans Pro", sans-serif;
  --global-font-size: 1.125rem;
  --global-text-color: #515151;
  --global-color-main: #102E46;
  --global-color-lightblue: #E9FBFF;
  --global-color-header: #1A6FB5;
  --global-color-white: #ffffff;
  --global-color-pale-white: #9b9b9b; /*Please name the variant, I don't know*/
}
p{
  font-weight: 300;
}
b{
  font-weight: 700;
  color: var(--global-text-color);
}
a{
  text-decoration: none !important;
}
h1, h2{
  position: relative;
  z-index: 1;
  padding-bottom: 15px;
  font-family: var(--global-font-family);
  font-weight: 700;
  color: var(--global-color-main);
}

h1:after {
  content: "";
  position: absolute;
  left: 3px;
  bottom: 0;
  width: 100px;
  border-bottom: 3px solid var(--global-color-main);
}

h2:after {
  content: "";
  position: absolute;
  left: 44%;
  bottom: 0;
  width: 100px;
  border-bottom: 3px solid var(--global-color-main);
}

.btn{
  min-width: 120px;
  height: 40px;
  border-radius: 25px !important;
}

.btn-primary,.offcanvas-end{
  background-color: var(--global-color-main) !important;
  color: var(--global-color-white) !important;
}

.btn-outline-primary{
  border-color: var(--global-color-header) !important;
  color: var(--global-color-header) !important;
}

.btn-outline-primary:hover {
  background-color: var(--global-color-main) !important;
  color: var(--global-color-white) !important;
}

.container-fluid{
  margin: 0 !important;
}

@media only screen and (max-width: 600px) {
  h2:after {
    left: 35%;
  }
}