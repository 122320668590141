.bannerText{
    padding: 1rem 3rem;
}
.bannerText p{
    line-height: 1.8;
    color: var(--global-text-color);
    padding: 2rem;
}
#search{
    background-color: var(--global-color-white);
    border-radius: 2rem;
    height: 50px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border: 1px solid #DEEEFE;
}
#search .form-control{
    width: 90%;
    display: inline-block;
    border: none;
    font-size: var(--global-font-size);
}
#search .form-control:focus{
    box-shadow: none;
}
#search svg{
    color: var(--global-color-main);
    width: 25px;
    height: 25px;
}
.carousel-item{
    left: 30px;
}
.carousel-item .col-md-2:first-child{
    margin-left: 5rem !important;
}
.carousel-item.active,.carousel-control-next, .carousel-control-prev{
    display: flex !important;
}
.bookLists h4{
    margin-bottom: 1.5rem;
    color: #102E46;
    padding-left: 0.5rem;
}
.item:first-child{
    margin-left: 0.5rem;
}
.bookItem .item{
    width:18.9%;
}
.bookItem .item img{
    width: 100%;
}
.item{
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    height: 380px;
    margin-right: 10px;
    cursor: pointer;
}
.item label{
    width: 100%;
    text-align: center;
    padding: 15px 10px;
    font-size: 0.95rem;
    background: var(--global-color-white);
    font-family: var(--global-font-family-mn);
}
.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.owl-prev span,
.owl-next span{
  font-size: 3rem;
  color: #4D2900;
}

.owl-prev {
  left: -2rem;
}

.owl-next {
  right: -2rem;
}
.owl-prev:hover,
.owl-next:hover{
    background: none !important;
    color: #102E46 !important;
}
.btn.seeAllBtn{
    width: 160px;
    height: 50px;
    font-size: 1.15rem;
    line-height: 1.8;
}

/* Card image loading */
.card__image img {
    width: 100%;
    height: 100%;
}
  
.card__image.loading {
    height: 300px;
    width: 100%;
}

/* Card title */
.card__title {
    padding: 8px;
    font-size: 22px;
    font-weight: 700;
}
  
.card__title.loading {
    height: 1rem;
    width: 50%;
    margin: 1rem;
    border-radius: 3px;
}

/* Card description */
.card__description {
    padding: 8px;
    font-size: 16px;
}
  
.card__description.loading {
    height: 3rem;
    margin: 1rem;
    border-radius: 3px;
}

/* The loading Class */
.loading {
    position: relative;
    background-color: #e2e2e2;
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
                right top, from(transparent), 
                color-stop(rgba(255, 255, 255, 0.2)),
                to(transparent));
                  
    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .carousel-item .col-md-2:first-child {
        margin-left: 1rem !important;
    }
    .bookLists h4 {
        margin-left: 3rem;
    }
    .carousel-item .col-md-2 label{
        font-size: 0.85rem;
    }
    .carousel-control-next, .carousel-control-prev{
        width: 10% !important;
    }
    .bannerText{
        padding: 1rem;
    }
    .bannerText p{
        padding: 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 480px) {
    .carousel-item .col-md-2:first-child {
        margin-left: 3rem !important;
    }
    .carousel-item .col-md-2{
        margin-bottom: 1rem;
        margin-left: 3rem !important;
    }
    .col-md-2 .w-100,.carousel-item .col-md-2 label {
        width: 60%!important;
    }
}